.netless-whiteboard div.highlight-box {
  border: 1px solid;
  box-shadow: 0 0 1px 0 #FFFFFF, inset 0 0 1px 0 #FFFFFF;
}
.netless-whiteboard div.editor-box > *.cursor-nwse {
  cursor: nwse-resize;
}
.netless-whiteboard div.editor-box > *.cursor-nesw {
  cursor: nesw-resize;
}
.netless-whiteboard div.editor-box > *.cursor-ns {
  cursor: ns-resize;
}
.netless-whiteboard div.editor-box > *.cursor-ew {
  cursor: ew-resize;
}
.netless-whiteboard div.editor-box {
  pointer-events: none;
  border: 1px solid;
  box-shadow: 0 0 1px 0 #FFFFFF, inset 0 0 1px 0 #FFFFFF;
}
.netless-whiteboard div.editor-box > div.operation-point-desktop {
  width: 7px;
  height: 7px;
  position: absolute;
  pointer-events: auto;
}
.netless-whiteboard div.editor-box > div.operation-point-touch {
  width: 33px;
  height: 33px;
  position: absolute;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.netless-whiteboard div.editor-box > div.operation-point-touch > * {
  pointer-events: none;
  width: 7px;
  height: 7px;
}
.netless-whiteboard div.editor-box > .operation-point-desktop.left-top {
  left: -4px;
  top: -4px;
}
.netless-whiteboard div.editor-box > .operation-point-desktop.right-top {
  right: -4px;
  top: -4px;
}
.netless-whiteboard div.editor-box > .operation-point-desktop.left-bottom {
  left: -4px;
  bottom: -4px;
}
.netless-whiteboard div.editor-box > .operation-point-desktop.right-bottom {
  right: -4px;
  bottom: -4px;
}
.netless-whiteboard div.editor-box > .operation-point-desktop.top {
  top: -4px;
}
.netless-whiteboard div.editor-box > .operation-point-desktop.right {
  right: -4px;
}
.netless-whiteboard div.editor-box > .operation-point-desktop.bottom {
  bottom: -4px;
}
.netless-whiteboard div.editor-box > .operation-point-desktop.left {
  left: -4px;
}
.netless-whiteboard div.editor-box > .operation-point-touch.left-top {
  left: -17px;
  top: -17px;
}
.netless-whiteboard div.editor-box > .operation-point-touch.right-top {
  right: -17px;
  top: -17px;
}
.netless-whiteboard div.editor-box > .operation-point-touch.left-bottom {
  left: -17px;
  bottom: -17px;
}
.netless-whiteboard div.editor-box > .operation-point-touch.right-bottom {
  right: -17px;
  bottom: -17px;
}
.netless-whiteboard div.editor-box > .operation-point-touch.top {
  top: -17px;
}
.netless-whiteboard div.editor-box > .operation-point-touch.right {
  right: -17px;
}
.netless-whiteboard div.editor-box > .operation-point-touch.bottom {
  bottom: -17px;
}
.netless-whiteboard div.editor-box > .operation-point-touch.left {
  left: -17px;
}
.netless-whiteboard div.editor-box-others {
  border: 1px solid;
}
.netless-whiteboard textarea {
  -webkit-appearance: none;
  -webkit-user-select: text;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  resize: none;
  overflow: hidden;
}
.netless-whiteboard .textarea-layout {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.netless-whiteboard .textarea-layout > * {
  position: absolute;
  pointer-events: auto;
}
.netless-whiteboard .textarea-border {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  border: 1px dashed;
}
.netless-whiteboard .textarea-border > * {
  position: absolute;
  pointer-events: auto;
}
.netless-whiteboard textarea.readonly-textarea {
  background: rgba(255, 255, 255, 0);
}
.netless-whiteboard textarea.editable-textarea {
  background: rgba(255, 255, 255, 0);
}
.netless-whiteboard textarea.my-textarea {
  pointer-events: auto;
}
.netless-whiteboard.cursor-hand {
  cursor: url("https://sdk.herewhite.com/resource/mouse-cursor/hand-cursor.svg") 0 0, auto;
  cursor: -webkit-image-set(url("https://sdk.herewhite.com/resource/mouse-cursor/hand-cursor.svg") 1x, url("https://sdk.herewhite.com/resource/mouse-cursor/hand-cursor%402x.png") 2x) 0 0, auto;
}
.netless-whiteboard.cursor-hand {
  cursor: url("https://sdk.herewhite.com/resource/mouse-cursor/hand-cursor.svg") 0 0, auto;
  cursor: -webkit-image-set(url("https://sdk.herewhite.com/resource/mouse-cursor/hand-cursor.svg") 1x, url("https://sdk.herewhite.com/resource/mouse-cursor/hand-cursor%402x.png") 2x) 0 0, auto;
}
.netless-whiteboard.cursor-selector {
  cursor: url("https://sdk.herewhite.com/resource/mouse-cursor/selector-cursor.png") 5 4, auto;
  cursor: -webkit-image-set(url("https://sdk.herewhite.com/resource/mouse-cursor/selector-cursor.png") 1x, url("https://sdk.herewhite.com/resource/mouse-cursor/selector-cursor%402x.png") 2x) 5 4, auto;
}
.netless-whiteboard.cursor-pencil {
  cursor: url("https://sdk.herewhite.com/resource/mouse-cursor/pencil-cursor.png") 2 22, auto;
  cursor: -webkit-image-set(url("https://sdk.herewhite.com/resource/mouse-cursor/pencil-cursor.png") 1x, url("https://sdk.herewhite.com/resource/mouse-cursor/pencil-cursor%402x.png") 2x) 2 22, auto;
}
.netless-whiteboard.cursor-eraser {
  cursor: url("https://sdk.herewhite.com/resource/mouse-cursor/easer-cursor.png") 8 18, auto;
  cursor: -webkit-image-set(url("https://sdk.herewhite.com/resource/mouse-cursor/easer-cursor.png") 1x, url("https://sdk.herewhite.com/resource/mouse-cursor/easer-cursor%402x.png") 2x) 8 18, auto;
}
.netless-whiteboard.cursor-nwse {
  cursor: nwse-resize;
}
.netless-whiteboard.cursor-nesw {
  cursor: nesw-resize;
}
.netless-whiteboard.cursor-ns {
  cursor: ns-resize;
}
.netless-whiteboard.cursor-ew {
  cursor: ew-resize;
}
.netless-whiteboard.cursor-rectangle {
  cursor: crosshair;
}
.netless-whiteboard.cursor-ellipse {
  cursor: crosshair;
}
.netless-whiteboard.cursor-text {
  cursor: text;
}
.netless-whiteboard * {
  pointer-events: none;
  user-select: none;
}
.cursor-selector  .ppt-event-source {
  cursor: pointer;
  pointer-events: auto;
  user-select: auto;
}
.netless-whiteboard {
  text-align: start;
}
.netless-whiteboard * > svg {
  text-align: start;
}
